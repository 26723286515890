import { IconName, IconType } from 'components/Icon';
import { MainColor, Size } from 'core/styles';
import ListLayout from 'layouts/ListLayout';
import MainLayout from 'layouts/MainLayout';
import { USER_STORE_KEY, UsersGetter, useUsersGetter } from 'store/users';
import { computed, defineComponent, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { SubscriptionRoutesName } from 'router/names';
import { useRoute } from 'vue-router';
import { IUser, Sex, User } from 'api/users/models/user';
import List from './components/List';
import Stories from './components/Stories';
import RaiseProfile from './components/RaiseProfile';
import BonusState from './components/BonusState';

const Catalog = defineComponent({
  components: {
    MainLayout,
    ListLayout,
    Stories,
    List,
    RaiseProfile,
    BonusState,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const isAuth = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.IsAuth}`]);
    const bonusStateRef = ref();

    const isFavoritesOnly = computed(() => route.name === 'favorites');

    const hasFilter = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.HasFilter}`]);

    const userList = computed(() => {
      if (isFavoritesOnly.value) return store.getters[`${USER_STORE_KEY}/${UsersGetter.Favorites}`];
      return store.getters[`${USER_STORE_KEY}/${UsersGetter.List}`];
    });

    const user = computed<IUser>(() => useUsersGetter<User>(UsersGetter.Profile));
    const isShowRaise = computed(() => user.value?.gender === Sex.Male || user.value?.age < 35);

    const hasNext = computed(() => {
      if (isFavoritesOnly.value) return false;
      return store.getters[`${USER_STORE_KEY}/${UsersGetter.ListHasNext}`];
    });

    const isStoriesOpen = ref(false);

    const bonusState = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`]?.bonus_state);
    watchEffect(() => {
      if (bonusState.value === 1) {
        bonusStateRef.value?.check();
      }
    });

    return {
      bonusStateRef,
      isAuth,
      isShowRaise,
      hasFilter,
      isFavoritesOnly,
      isStoriesOpen,

      userList,
      hasNext,

      IconName,
      IconType,
      Size,
      MainColor,
      SubscriptionRoutesName,
    };
  },
});

export default Catalog;
