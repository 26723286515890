<template>
  <about-paid-services
    :local-storage-key="StorageKeys.AboutRaiseViewed"
    :button-title="$t('components.aboutRaise.submit')"
    @submit="handleSubmit"
  >
    <template #default="{ themeColor }">
      <txt :type="TxtType.Heading2">{{ $t('components.aboutRaise.title') }}</txt>
      <div class="about-raise-crown">
        <div
          v-if="themeColor === 'blue'"
          class="about-raise-crown__wrapper"
        >
          <img src="./assets/blue-1.svg">
          <img
            class="about-raise-crown__1"
            src="./assets/blue-2.svg"
          >
          <img
            class="about-raise-crown__2"
            src="./assets/blue-2.svg"
          >
          <img
            class="about-raise-crown__3"
            src="./assets/blue-2.svg"
          >
          <img
            class="about-raise-crown__4"
            src="./assets/blue-2.svg"
          >
        </div>
        <div
          v-else-if="themeColor === 'red'"
          class="about-raise-crown__wrapper"
        >
          <img src="./assets/red-1.svg">
          <img
            class="about-raise-crown__1"
            src="./assets/red-2.svg"
          >
          <img
            class="about-raise-crown__2"
            src="./assets/red-2.svg"
          >
          <img
            class="about-raise-crown__3"
            src="./assets/red-2.svg"
          >
          <img
            class="about-raise-crown__4"
            src="./assets/red-2.svg"
          >
        </div>
        <div
          v-else
          class="about-raise-crown__wrapper"
        >
          <img src="./assets/lavender-1.svg">
          <img
            class="about-raise-crown__1"
            src="./assets/lavender-2.svg"
          >
          <img
            class="about-raise-crown__2"
            src="./assets/lavender-2.svg"
          >
          <img
            class="about-raise-crown__3"
            src="./assets/lavender-2.svg"
          >
          <img
            class="about-raise-crown__4"
            src="./assets/lavender-2.svg"
          >
        </div>
      </div>
      <p>{{ $t('components.aboutRaise.description1') }}</p>
      <p class="my-4">{{ $t('components.aboutRaise.description2') }}</p>
      <p>{{ $tc('components.aboutRaise.price', serviceSettings.price) }}</p>
      <p>{{ $tc('components.aboutRaise.duration', serviceSettings.days) }}</p>
    </template>
  </about-paid-services>
</template>

<script lang="ts" src="./AboutRaise.ts" />
<style lang="scss" src="./aboutRaise.scss" />
