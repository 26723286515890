<template>
  <bonus-state ref="bonusStateRef" />
  <main-layout>
    <list-layout
      :title="$t('pages.catalog.title')"
      class="catalog"
    >
      <template #info>
        <icon-btn
          v-if="!isAuth"
          class="catalog__arrow-back"
          @click="$router.push('/')"
        >
          <icon
            :name="IconName.ArrowBack"
            :type="IconType.Contour"
          />
        </icon-btn>
        <stories
          v-if="isAuth"
          v-model:isStoriesOpen="isStoriesOpen"
        />
        <raise-profile v-if="isShowRaise" />
      </template>

      <template
        v-if="!isStoriesOpen"
        #body
      >
        <router-view />
      </template>
    </list-layout>
  </main-layout>
</template>

<script lang="ts" src="./Catalog.ts"></script>
<style lang="scss" src="./catalog.scss"></style>
