import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { IUser } from 'api/users/models/user';
import { IconType, IconName } from 'components/Icon';
import {
  UsersGetter, useUsersGetter, USER_STORE_KEY,
} from 'store/users';
import Empty from 'components/Empty';
import ListLoader from 'components/ListLoader';
import UnauthorizedPrompt from 'components/UnauthorizedPrompt';
import { PropType } from '@vue/runtime-core';
import VueUser from '../User';

const List = defineComponent({
  components: {
    User: VueUser,
    Empty,
    ListLoader,
    UnauthorizedPrompt,
  },

  props: {
    listLoaderShow: {
      type: Boolean,
      default: true,
    },
    isFetching: {
      type: Boolean,
      required: true,
    },
    users: {
      type: Array as PropType<IUser[]>,
      required: true,
    },
    hasNext: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['load'],

  setup() {
    const store = useStore();
    const { t } = useI18n();

    const isAuth = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.IsAuth}`]);

    const hasFilter = computed(() => useUsersGetter<boolean>(UsersGetter.HasFilter));

    const isAuthorizedModalShow = ref(false);
    const unauthorizedModalTitle = ref('');

    function handleUnauthorizedSendMessage() {
      isAuthorizedModalShow.value = true;
      unauthorizedModalTitle.value = t('layouts.userLayout.unauthorized.message');
    }
    function handleUnauthorizedAddToFavorite() {
      isAuthorizedModalShow.value = true;
      unauthorizedModalTitle.value = t('layouts.userLayout.unauthorized.like');
    }

    return {
      t,
      isAuth,
      isAuthorizedModalShow,
      unauthorizedModalTitle,
      handleUnauthorizedSendMessage,
      handleUnauthorizedAddToFavorite,

      hasFilter,
      IconName,
      IconType,
    };
  },
});

export default List;
