import { computed, defineComponent, nextTick, ref } from 'vue';
import { IconName, IconType } from 'components/Icon';
import { MainColor, Size } from 'core/styles';
import { USER_STORE_KEY, UsersAction, UsersGetter } from 'store/users';
import Prompt from 'components/Prompt';
import { useStore } from 'vuex';
import { useApiErrors } from 'composables/apiErrors';
import { IProfile, Sex } from 'api/users/models/user';
import { useCurrency } from 'composables/currency';
import { ServiceNames, useServiceSettings } from 'composables/serviceSettings';
import { toastEmitter, ToastType } from 'components/Toasts';
import { useI18n } from 'vue-i18n';
import InsufficientPrompt from 'components/InsufficientPrompt';
import { storageService, StorageKeys } from 'storage';
import AboutRaise from './components/AboutRaise';

const RaiseProfile = defineComponent({
  components: {
    Prompt,
    InsufficientPrompt,
    AboutRaise,
  },
  setup() {
    const { t } = useI18n();
    const { showError } = useApiErrors();
    const { formatCurrency } = useCurrency();
    const store = useStore();
    const raiseProfileRef = ref<HTMLDivElement>();
    const serviceInfo = useServiceSettings(ServiceNames.TakeMeToTheTop);
    const isClosed = ref(false);
    const isAuth = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.IsAuth}`]);

    const insufficientPrompt = ref(false);
    const profile = computed<IProfile>(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`]);

    const isAlreadyRaised = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.IsProfileRaised}`]);

    function close() {
      isClosed.value = true;
    }

    async function raiseProfile() {
      if (profile.value.balance < serviceInfo.value.price) {
        insufficientPrompt.value = true;
        return;
      }

      try {
        await store.dispatch(`${USER_STORE_KEY}/${UsersAction.RaiseProfile}`);

        toastEmitter.emit('toast', {
          type: ToastType.Success,
          message: t('pages.catalog.raiseProfile.success'),
        });
        close();
      } catch (e) {
        showError(e);
      }
    }

    const isAboutRaisePopupShow = ref(false);
    const showAboutRaisePopup = () => {
      if (storageService.getItem(StorageKeys.AboutRaiseViewed) === '1') return;
      isAboutRaisePopupShow.value = true;
    };
    const handleAboutRaiseSubmit = () => {
      isAboutRaisePopupShow.value = false;
      nextTick(() => {
        raiseProfileRef.value?.click();
      });
    };

    return {
      isAuth,
      raiseProfileRef,
      serviceInfo,
      isClosed,
      isAlreadyRaised,
      profile,
      formatCurrency,
      isAboutRaisePopupShow,
      showAboutRaisePopup,
      handleAboutRaiseSubmit,

      insufficientPrompt,

      raiseProfile,
      close,

      IconType,
      IconName,
      Size,
      Sex,
      MainColor,
    };
  },
});

export default RaiseProfile;
