<template>
  <transition name="bonus-state">
    <div
      v-if="isShow"
      class="bonus-state"
    >
      {{ message }}

      <icon-btn
        class="bonus-state__exit"
        @click="hide"
      >
        <icon
          :name="IconName.Crosses"
          :size="Size.Xxs"
          :type="IconType.Contour"
          :color="MainColor.White"
        />
      </icon-btn>

      <div class="bonus-state__icons">
        <icon
          v-for="key in 5"
          :key="key"
          :name="IconName.Checked"
          :size="Size.L"
          :type="IconType.Fill"
          class="bonus-state__mark"
          :class="{ 'bonus-state__mark--filled': key <= days }"
        />
      </div>
    </div>
  </transition>
</template>

<script lang="ts" src="./BonusState.ts" />
<style lang="scss" src="./bonusState.scss" />
