import { defineComponent } from 'vue';
import { IconName, IconType } from 'components/Icon';
import { Size } from 'core/styles';
import { TxtType } from 'components/Txt';
import { BtnType } from 'components/Btn';
import { useRouter } from 'vue-router';

const UnauthorizedPrompt = defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const router = useRouter();
    function close() {
      emit('update:modelValue', false);
    }

    function toSignIn() {
      router.push({ name: 'signin' });
      close();
    }

    function toSignUp() {
      router.push({ name: 'signup' });
      close();
    }

    return {
      close,
      toSignIn,
      toSignUp,

      IconName,
      IconType,
      Size,
      TxtType,
      BtnType,
    };
  },
});

export default UnauthorizedPrompt;
