import { defineComponent } from 'vue';
import { TxtType } from 'components/Txt';
import { ServiceNames, useServiceSettings } from 'composables/serviceSettings';
import AboutPaidServices from 'components/AboutPaidServices';
import { StorageKeys } from 'storage';

const AboutRaise = defineComponent({
  components: {
    AboutPaidServices,
  },
  emits: ['submit'],
  setup(_, { emit }) {
    const serviceSettings = useServiceSettings(ServiceNames.TakeMeToTheTop);

    function handleSubmit() {
      emit('submit');
    }

    return {
      handleSubmit,
      serviceSettings,

      StorageKeys,
      TxtType,
    };
  },
});

export default AboutRaise;
