<template>
  <unauthorized-prompt v-model="isAuthorizedModalShow">
    {{ unauthorizedModalTitle }}
  </unauthorized-prompt>
  <user
    v-for="user in users"
    :key="user.id"
    :user="user"
    @unauthorized-like="handleUnauthorizedAddToFavorite"
    @unauthorized-message="handleUnauthorizedSendMessage"
  />
  <list-loader
    v-if="isAuth"
    v-show="listLoaderShow && users.length !== 0"
    :is-loading="isFetching"
    :has-next="hasNext"
    @load="$emit('load')"
  />
  <div
    v-else
    class="user-list-authorize"
  >
    <txt>{{ $t('pages.catalog.signUpToSee') }}</txt>
  </div>
</template>

<script lang="ts" src="./List.ts"></script>
<style lang="scss" src="./list.scss"></style>
