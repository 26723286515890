import { defineComponent, ref } from 'vue';
import { Api } from 'api/api';
import { useI18n } from 'vue-i18n';
import { BonusState as IBonusState } from 'api/users/models/bonusState/interfaces';
import { IconName, IconType } from 'components/Icon';
import { MainColor, Size } from 'core/styles';
import { StorageKeys, storageService } from 'storage';
import dayjs from 'dayjs';
import { store } from 'store/store';
import { USER_STORE_KEY, UsersMutation } from 'store/users';

const BonusState = defineComponent({
  setup() {
    const { t } = useI18n();
    const isShow = ref(false);
    const show = () => { isShow.value = true; };
    const hide = () => { isShow.value = false; };

    const message = ref('');
    const setText = (item: IBonusState) => {
      message.value = t('pages.catalog.bonusState.message', { package: item.package?.title, duration: t('pages.catalog.bonusState.duration', { n: item.package?.duration }, +(item.package?.duration || 0)) });
    };
    const setSuccessText = (item: IBonusState) => {
      message.value = t('pages.catalog.bonusState.successMessage', { package: item.package?.title, duration: t('pages.catalog.bonusState.duration', { n: item.package?.duration }, +(item.package?.duration || 0)) });
    };

    const days = ref(0);
    const setDays = (item: IBonusState) => {
      days.value = (item.complete_activation ? 5 : item.days) || 0;
    };

    async function check() {
      const lastFetch = storageService.getItem(StorageKeys.BonusStateLastFetch);
      if (lastFetch && dayjs(new Date(lastFetch)).isToday()) return;

      const { data } = await Api.userService.fetchBonusState();
      storageService.setItem(StorageKeys.BonusStateLastFetch, (new Date()).toISOString());

      if (!data.status) return;
      setDays(data);
      show();
      if (data.complete_activation) {
        setSuccessText(data);
        if (data.profile) store.commit(`${USER_STORE_KEY}/${UsersMutation.SetProfile}`, data.profile);
        return;
      }
      setText(data);
    }

    return {
      check,
      message,
      days,
      isShow,
      hide,

      IconName,
      IconType,
      Size,
      MainColor,
    };
  },
});

export default BonusState;
