<template>
  <short-popup
    :model-value="modelValue"
    class="unauthorized-prompt"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <txt :type="TxtType.Heading4">
      <slot />
    </txt>
    <div class="unauthorized-prompt__btn-wrapper">
      <btn
        w="100%"
        @click="toSignUp"
      >
        {{ $t('components.unauthorizedPrompt.signUp') }}
      </btn>
      <span class="unauthorized-prompt__or">
        {{ $t('components.unauthorizedPrompt.or') }}
      </span>
      <btn
        w="100%"
        :type="BtnType.Secondary"
        @click="toSignIn"
      >
        {{ $t('components.unauthorizedPrompt.signIn') }}
      </btn>
    </div>
  </short-popup>
</template>

<script lang="ts" src="./UnauthorizedPrompt.ts" />
<style lang="scss" src="./unauthorizedPrompt.scss" />
