<template>
  <about-raise
    v-if="isAboutRaisePopupShow"
    @submit="handleAboutRaiseSubmit"
  />
  <prompt
    v-if="isAuth && !isAboutRaisePopupShow"
    :title="$t('pages.catalog.raiseProfile.submitPrompt.title', { price: formatCurrency(serviceInfo.price) })"
    ok-text-path="pages.catalog.raiseProfile.submitPrompt.confirm"
    @ok="raiseProfile"
  >
    <template
      v-if="!isClosed && !isAlreadyRaised"
      #activator
    >
      <div
        ref="raiseProfileRef"
        class="raise-profile"
        @click="showAboutRaisePopup"
      >
        <icon-btn
          class="raise-profile_close"
          :size="Size.M"
          @click.stop="close"
        >
          <icon
            :type="IconType.Contour"
            :name="IconName.Crosses"
            :size="Size.Xxs"
            :color="MainColor.White"
          />
        </icon-btn>
        <div class="raise-profile_image">
          <img
            v-if="profile.gender === Sex.Male"
            src="@/assets/images/svg/men.svg"
            alt="men-icon"
          >
          <img
            v-else
            src="@/assets/images/svg/women.svg"
            alt="women-icon"
          >
          <div class="raise-profile_premium-icon-wrapper">
            <icon
              class="raise-profile_premium-icon"
              :name="IconName.Premium"
              :type="IconType.Fill"
              :size="Size.Xxs"
              :color="MainColor.White"
            />
          </div>
        </div>
        <div class="raise-profile_content">
          <div class="raise-profile_title">
            {{ $t('pages.catalog.raiseProfile.title') }}
          </div>
          <div class="raise-profile_subtitle">
            {{ $t('pages.catalog.raiseProfile.subtitle', { price: formatCurrency(serviceInfo.price) }) }}
            {{ $tc('pages.catalog.raiseProfile.days', serviceInfo.days, { days: serviceInfo.days }) }}
          </div>
        </div>
      </div>
    </template>
  </prompt>
  <insufficient-prompt v-model="insufficientPrompt" />
</template>

<script lang="ts" src="./RaiseProfile.ts" />
<style lang="scss" src="./raiseProfile.scss" />
